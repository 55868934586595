<template>
  <div class="pa-9 pa-md-6 pa-lg-9">
    <v-container>
      <template v-if="loading">
        <v-skeleton-loader
          type="card, card, article, date-picker"
        ></v-skeleton-loader>
      </template>
      <template v-else>
        <v-row align="center">
          <v-col cols="auto">
            <div class="customer-name text-heading-1">
              {{ customer.name }}
            </div>
            <div class="customer-id mt-2">顧客 ID: {{ customer.id }}</div>
          </v-col>

          <v-col cols="auto">
            <div class="vertical-divider"></div>
          </v-col>

          <v-col cols="auto">
            <v-btn
              class="btn-menu"
              active-class="btn-menu-active"
              depressed
              text
              :to="getCustomerViewRoute('basic')"
              :ripple="false"
              >基本情報</v-btn
            >
          </v-col>

          <v-col cols="auto">
            <v-btn
              class="btn-menu"
              active-class="btn-menu-active"
              depressed
              text
              :to="getCustomerViewRoute('VisitMemo')"
              exact
              :ripple="false"
              >訪問 ({{ customer?.visit_memo_count }})</v-btn
            >
          </v-col>

          <v-col cols="auto">
            <v-btn
              class="btn-menu"
              active-class="btn-menu-active"
              depressed
              text
              :to="getCustomerViewRoute('task')"
              exact
              :ripple="false"
              >タスク ({{ count?.tasks }})</v-btn
            >
          </v-col>

          <v-col cols="auto">
            <v-btn
              class="btn-menu"
              active-class="btn-menu-active"
              depressed
              text
              :to="getCustomerViewRoute('photos')"
              exact
              :ripple="false"
              >写真 ({{ count?.photos || 0 }})</v-btn
            >
          </v-col>

          <v-col cols="auto">
            <v-btn
              class="btn-menu"
              active-class="btn-menu-active"
              depressed
              text
              :to="getCustomerViewRoute(claimOrAfterService)"
              exact
              :ripple="false"
              >ｸﾚｰﾑ/ｱﾌﾀ- ({{ count?.claimsAndAfterServices || 0 }})</v-btn
            >
          </v-col>

          <v-col cols="auto" class="flex-grow-1 text-right text-heading-3">
            <div class="customer-detail-date mb-2">
              <v-icon size="16">$workTime</v-icon>
              {{ customer.updated_at | formatDate }}
            </div>
            <div class="customer-detail-last-updated">
              <v-icon size="14">$user</v-icon>
              {{
                customer.updated_by_user
                  ? customer.updated_by_user.full_name
                  : '-'
              }}
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" v-if="!isBasicView && !hideView">
            <SiteSelect
              :customer="customer"
              :allPrefectures="allPrefectures"
              :allServices="servicesBySite"
              :project-id.sync="project_id"
              @change:site="updateTableData"
              @change:service="updateTableData"
            />
            <v-progress-linear
              v-if="smallLoading"
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-col>
          <v-col cols="12">
            <router-view
              :customer="customer"
              :project-id.sync="project_id"
              @updateCustomer="updateCustomer"
            ></router-view>
          </v-col>

          <!--          <v-col cols="12" v-if="!hideView">-->
          <!--            <UpdateList-->
          <!--              :customers="customers"-->
          <!--              :tasks="tasks"-->
          <!--              :customer="customer"-->
          <!--              @changecustomer="changeCustomer"-->
          <!--              @load:update-list="lazyLoadUpdateList"-->
          <!--            />-->
          <!--          </v-col>-->
        </v-row>
      </template>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from '@/plugins/dayjs'
import SiteSelect from '@/components/admin/partials/Customer/View/SiteSelect'
import UpdateList from '@/components/admin/partials/Customer/View/Basic/UpdateHistory/UpdateList'
import { omit } from 'lodash'
export default {
  name: 'Detail',
  // eslint-disable-next-line vue/no-unused-components
  components: { UpdateList, SiteSelect },
  props: ['customer_id', 'site_id'],
  data() {
    return {
      loading: true,
      smallLoading: false,
      customers: [],
      tasks: [],
      page: {
        customer: 0,
        task: 0
      },
      project_id: null,
      subLoading: false
    }
  },
  computed: {
    ...mapGetters([
      'allCustomers',
      'allTasksData',
      'allPrefectures',
      'servicesBySite',
      'customer',
      'customerPagination',
      'taskPagination',
      'customerTasksCount',
      'customerClaimsCount',
      'customerCoatingAfterCount',
      'customerVisitMemosCount'
    ]),
    isBasicView() {
      return this.$route.name === 'CustomerViewDetailBasic'
    },
    hideView() {
      return this.$route.name === 'CustomerViewDetailVisitMemo'
    },
    sites() {
      return this.customer?.sites
    },
    selectedSite() {
      return this.sites?.find(site => site.id == this.site_id)
    },
    projects() {
      return this.selectedSite?.projects
    },
    selectedProject() {
      return this.projects?.find(project => project.id == this.project_id)
    },
    claimOrAfterService() {
      return this.selectedProject?.service_type_id === 0
        ? 'afterService'
        : 'claim'
    },
    count() {
      let tasks = this.sites?.reduce((sum, site) => {
        return sum + site.tasks_count
      }, 0)

      let photos = this.sites?.reduce((sum, site) => {
        return sum + site.photos_count
      }, 0)

      // Get the total amount of either claims or after services
      let claimsAndAfterServices = 0

      // Because either claims or after services are related with project, we must iterate through the projects instead
      // of directly from sites.
      this.sites?.forEach(site => {
        claimsAndAfterServices += site.projects.reduce((sum, project) => {
          let count =
            project.service_type_id === 0
              ? project.coating_afters_count
              : project.claims_count
          return sum + count
        }, 0)
      })
      return { tasks: tasks, photos: photos, claimsAndAfterServices }
    },

    APIAction() {
      if (this.$route.name === 'CustomerViewDetailTask') {
        return 'CUSTOMER_TASK_GET'
      } else if (this.$route.name === 'CustomerViewDetailPhotos') {
        return 'GET_ALL_PHOTO_BY_CUSTOMER_ID'
      } else if (this.$route.name === 'CustomerViewDetailClaim') {
        return 'CUSTOMER_GET_ALL_CLAIM'
      } else if (this.$route.name === 'CustomerViewDetailAfterService') {
        return 'CUSTOMER_GET_ALL_AFTER_SERVICE'
      }
      return ''
    }
  },
  methods: {
    async updateSite() {
      this.project_id = this.selectedSite?.projects[0].id
      this.loading = false
    },
    updateCustomer(value) {
      this.loading = true
      this.$store
        .dispatch('CUSTOMER_GET', value ? value : this.customer_id)
        .then(() => {
          this.updateSite()
          if (this.$store.getters.selectedProject) {
            this.project_id = this.$store.getters.selectedProject
            this.$store.commit('resetSelectedProject')
          } else {
            this.project_id = this.selectedSite?.projects[0]?.id
          }
          this.redirectToClaimOrAfterService()
        })
    },
    async fetchCustomerHistory(options) {
      let param = {
        orderBy: 'updated_at',
        orderDesc: 'desc',
        paginate: 10,
        exception: this.customer_id,
        history: true,
        ...options
      }
      await this.$store.dispatch('CUSTOMER_GET_ALL', param)
      this.page.customer = this.customerPagination.current_page
      this.customers.push(...this.allCustomers)
    },
    async fetchTaskHistory(options) {
      let param = {
        orderBy: 'updated_at',
        orderDesc: 'desc',
        paginate: 10,
        ...options
      }
      await this.$store.dispatch('GET_ALL_TASK', param)
      this.page.task = this.taskPagination.current_page
      this.tasks.push(...this.allTasksData)
    },
    changeCustomer(value, site) {
      this.loading = true
      this.$router.push({
        name: 'CustomerViewDetailBasic',
        params: {
          customer_id: value,
          site_id: site
        }
      })
      this.$emit('updateCustomer', value)
    },
    lazyLoadUpdateList(type) {
      if (type === 'customer') {
        if (this.page.customer !== this.customerPagination.total_pages) {
          this.page.customer++
          this.smallLoading = true
          this.fetchCustomerHistory({
            page: this.page.customer
          })
          this.smallLoading = false
        }
      } else {
        if (this.page.task !== this.taskPagination.total_pages) {
          this.page.task++
          this.smallLoading = true
          this.fetchTaskHistory({
            page: this.page.task
          })
          this.smallLoading = false
        }
      }
    },
    getCustomerViewRoute(part) {
      return {
        name: `CustomerViewDetail${part.charAt(0).toUpperCase()}${part.slice(
          1
        )}`,
        params: {
          customer_id: this.$route.params.customer_id,
          site_id: this.$route.params.site_id
        }
      }
    },
    redirectToClaimOrAfterService() {
      const selectedProject = this.projects?.find(
        project => project.id == this.project_id
      )
      let param = {
        customer_id: this.customer.id,
        site_id: this.$route.params.site_id,
        project_id: this.project_id
      }
      if (selectedProject?.service_type_id === 0) {
        if (this.$route.name === 'CustomerViewDetailClaim') {
          this.$router.push({ name: 'CustomerViewDetailAfterService' })
        }
      } else {
        if (this.$route.name === 'CustomerViewDetailAfterService') {
          this.$router.push({ name: 'CustomerViewDetailClaim' })
          this.$store.dispatch(
            'CUSTOMER_TASK_GET',
            omit(param, 'paginate', 'id')
          )
        }
      }
    },
    updateTableData() {
      this.$nextTick(async () => {
        const selectedProject = this.projects?.find(
          project => project.id == this.project_id
        )
        let param = {
          id: this.customer.id,
          customer_id: this.customer.id,
          site_id: this.$route.params.site_id,
          project_id: this.project_id,
          paginate: 10
        }
        this.redirectToClaimOrAfterService()
        if (selectedProject?.service_type_id === 0) {
          param.coating_id = selectedProject?.svc_coating_id
        }
        if (this.$route.name === 'CustomerViewDetailClaim') {
          await this.$store.dispatch(
            'CUSTOMER_TASK_GET',
            omit(param, 'paginate', 'id')
          )
        }
        await this.$store.dispatch(this.APIAction, param)
      })
    }
  },
  watch: {
    $route: {
      handler(to, from) {
        this.$nextTick(() => {
          document.title = `${this.customer?.name} - ${document.title}`
        })
      }
    },
    site_id: {
      handler() {
        this.updateSite()
      }
    }
  },
  async created() {
    await this.updateCustomer()
    let param = {
      partner: {
        type: 'all'
      }
    }
    await this.$store.dispatch('PARTNER_GET_ALL', param.partner)
    await this.$store.dispatch('PREFECTURE_GET_ALL')
    await this.$store.dispatch('SERVICE_GET_ALL')
    if (this.$route.name === 'CustomerViewDetailClaim') {
      param = {
        customer_id: this.$route.params?.customer_id,
        site_id: this.$route.params?.site_id,
        project_id: this.project_id
      }
      await this.$store.dispatch('CUSTOMER_TASK_GET', param)
    }
    this.$store.dispatch('DROPDOWN_USER_LIST')
    document.title = `${this.customer?.name} - ${document.title}`
  },
  filters: {
    formatDate(value) {
      if (value) {
        return dayjs(value).format('YYYY/MM/DD')
      }
    }
  }
}
</script>

<style lang="scss" src="./Detail.scss" scoped></style>
