var render = function () {
  var _vm$selectedSite, _vm$selectedSite2, _vm$selectedProject;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "customer-box pa-8"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "customer-name-top font-weight-medium mb-2"
  }, [_vm._v(" " + _vm._s(_vm.customer.furigana_name) + " ")]), _c('div', {
    staticClass: "customer-name-bottom font-weight-medium"
  }, [_vm._v(" " + _vm._s(_vm.customer.name) + " ")])])], 1), _c('v-row', {
    staticClass: "mb-5"
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    staticClass: "customer-box-form-input",
    attrs: {
      "items": _vm.sites,
      "hide-details": "",
      "placeholder": "顧客名 / 物件名を入力してください",
      "outlined": "",
      "dense": "",
      "color": "#4F55A7",
      "item-color": "#4F55A7",
      "background-color": "white"
    },
    on: {
      "change": function change($event) {
        return _vm.$emit('change:site', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.text) + " "), item.count > 0 ? [_vm._v(" (" + _vm._s(item.count) + ") ")] : _vm._e()];
      }
    }, {
      key: "selection",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.text) + " "), item.count > 0 ? [_vm._v(" (" + _vm._s(item.count) + ") ")] : _vm._e()];
      }
    }]),
    model: {
      value: _vm.siteId,
      callback: function callback($$v) {
        _vm.siteId = $$v;
      },
      expression: "siteId"
    }
  })], 1), _c('v-icon', {
    attrs: {
      "color": "rgba(79, 85, 167, 0.5)"
    }
  }, [_vm._v("mdi-menu-right")]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    staticClass: "customer-box-form-input",
    attrs: {
      "items": _vm.services,
      "hide-details": "",
      "placeholder": "Related service type",
      "outlined": "",
      "dense": "",
      "color": "#4F55A7",
      "item-color": "#4F55A7",
      "background-color": "white"
    },
    on: {
      "change": function change($event) {
        return _vm.$emit('change:service', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.text) + " "), item.count > 0 ? [_vm._v(" (" + _vm._s(item.count) + ") ")] : _vm._e()];
      }
    }, {
      key: "selection",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.text) + " "), item.count > 0 ? [_vm._v(" (" + _vm._s(item.count) + ") ")] : _vm._e()];
      }
    }]),
    model: {
      value: _vm._projectId,
      callback: function callback($$v) {
        _vm._projectId = $$v;
      },
      expression: "_projectId"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "customer-box-detail-headline mb-3",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("物件名")]), _c('v-col', {
    staticClass: "customer-box-detail-content font-weight-bold",
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(" " + _vm._s(((_vm$selectedSite = _vm.selectedSite) === null || _vm$selectedSite === void 0 ? void 0 : _vm$selectedSite.name) || '-') + " ")]), _c('v-col', {
    staticClass: "customer-box-detail-headline mb-3",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("物件名（かな）")]), _c('v-col', {
    staticClass: "customer-box-detail-content font-weight-bold",
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(" " + _vm._s(((_vm$selectedSite2 = _vm.selectedSite) === null || _vm$selectedSite2 === void 0 ? void 0 : _vm$selectedSite2.furigana_name) || '-') + " ")]), _c('v-col', {
    staticClass: "customer-box-detail-headline mb-3",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("サービス ")]), _c('v-col', {
    staticClass: "customer-box-detail-content",
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(" " + _vm._s(((_vm$selectedProject = _vm.selectedProject) === null || _vm$selectedProject === void 0 ? void 0 : _vm$selectedProject.service_type.name) || '-') + " ")]), _c('v-col', {
    staticClass: "customer-box-detail-headline mb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("所在地")]), _c('v-col', {
    staticClass: "customer-box-detail-content",
    attrs: {
      "cols": "10"
    }
  }, [_c('span', {
    staticClass: "customer-address",
    on: {
      "click": function click($event) {
        return _vm.goToAddress(_vm.address);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.address || '-') + " "), _vm.address.trim().length ? _c('v-icon', {
    attrs: {
      "size": "20"
    }
  }, [_vm._v("$newTab")]) : _vm._e()], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }