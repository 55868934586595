var render = function () {
  var _vm$customer, _vm$count, _vm$count2, _vm$count3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pa-9 pa-md-6 pa-lg-9"
  }, [_c('v-container', [_vm.loading ? [_c('v-skeleton-loader', {
    attrs: {
      "type": "card, card, article, date-picker"
    }
  })] : [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "customer-name text-heading-1"
  }, [_vm._v(" " + _vm._s(_vm.customer.name) + " ")]), _c('div', {
    staticClass: "customer-id mt-2"
  }, [_vm._v("顧客 ID: " + _vm._s(_vm.customer.id))])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "vertical-divider"
  })]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "btn-menu",
    attrs: {
      "active-class": "btn-menu-active",
      "depressed": "",
      "text": "",
      "to": _vm.getCustomerViewRoute('basic'),
      "ripple": false
    }
  }, [_vm._v("基本情報")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "btn-menu",
    attrs: {
      "active-class": "btn-menu-active",
      "depressed": "",
      "text": "",
      "to": _vm.getCustomerViewRoute('VisitMemo'),
      "exact": "",
      "ripple": false
    }
  }, [_vm._v("訪問 (" + _vm._s((_vm$customer = _vm.customer) === null || _vm$customer === void 0 ? void 0 : _vm$customer.visit_memo_count) + ")")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "btn-menu",
    attrs: {
      "active-class": "btn-menu-active",
      "depressed": "",
      "text": "",
      "to": _vm.getCustomerViewRoute('task'),
      "exact": "",
      "ripple": false
    }
  }, [_vm._v("タスク (" + _vm._s((_vm$count = _vm.count) === null || _vm$count === void 0 ? void 0 : _vm$count.tasks) + ")")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "btn-menu",
    attrs: {
      "active-class": "btn-menu-active",
      "depressed": "",
      "text": "",
      "to": _vm.getCustomerViewRoute('photos'),
      "exact": "",
      "ripple": false
    }
  }, [_vm._v("写真 (" + _vm._s(((_vm$count2 = _vm.count) === null || _vm$count2 === void 0 ? void 0 : _vm$count2.photos) || 0) + ")")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "btn-menu",
    attrs: {
      "active-class": "btn-menu-active",
      "depressed": "",
      "text": "",
      "to": _vm.getCustomerViewRoute(_vm.claimOrAfterService),
      "exact": "",
      "ripple": false
    }
  }, [_vm._v("ｸﾚｰﾑ/ｱﾌﾀ- (" + _vm._s(((_vm$count3 = _vm.count) === null || _vm$count3 === void 0 ? void 0 : _vm$count3.claimsAndAfterServices) || 0) + ")")])], 1), _c('v-col', {
    staticClass: "flex-grow-1 text-right text-heading-3",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "customer-detail-date mb-2"
  }, [_c('v-icon', {
    attrs: {
      "size": "16"
    }
  }, [_vm._v("$workTime")]), _vm._v(" " + _vm._s(_vm._f("formatDate")(_vm.customer.updated_at)) + " ")], 1), _c('div', {
    staticClass: "customer-detail-last-updated"
  }, [_c('v-icon', {
    attrs: {
      "size": "14"
    }
  }, [_vm._v("$user")]), _vm._v(" " + _vm._s(_vm.customer.updated_by_user ? _vm.customer.updated_by_user.full_name : '-') + " ")], 1)])], 1), _c('v-row', [!_vm.isBasicView && !_vm.hideView ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('SiteSelect', {
    attrs: {
      "customer": _vm.customer,
      "allPrefectures": _vm.allPrefectures,
      "allServices": _vm.servicesBySite,
      "project-id": _vm.project_id
    },
    on: {
      "update:projectId": function updateProjectId($event) {
        _vm.project_id = $event;
      },
      "update:project-id": function updateProjectId($event) {
        _vm.project_id = $event;
      },
      "change:site": _vm.updateTableData,
      "change:service": _vm.updateTableData
    }
  }), _vm.smallLoading ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  }) : _vm._e()], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('router-view', {
    attrs: {
      "customer": _vm.customer,
      "project-id": _vm.project_id
    },
    on: {
      "update:projectId": function updateProjectId($event) {
        _vm.project_id = $event;
      },
      "update:project-id": function updateProjectId($event) {
        _vm.project_id = $event;
      },
      "updateCustomer": _vm.updateCustomer
    }
  })], 1)], 1)]], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }