<template>
  <v-container class="customer-box pa-8">
    <v-row>
      <v-col cols="12">
        <div class="customer-name-top font-weight-medium mb-2">
          {{ customer.furigana_name }}
        </div>
        <div class="customer-name-bottom font-weight-medium">
          {{ customer.name }}
        </div>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col cols="4">
        <v-select
          v-model="siteId"
          :items="sites"
          class="customer-box-form-input"
          hide-details
          placeholder="顧客名 / 物件名を入力してください"
          outlined
          dense
          color="#4F55A7"
          item-color="#4F55A7"
          background-color="white"
          @change="$emit('change:site', $event)"
        >
          <template v-slot:item="{ item }">
            {{ item.text }}
            <template v-if="item.count > 0">
              ({{ item.count }})
            </template>
          </template>
          <template v-slot:selection="{ item }">
            {{ item.text }}
            <template v-if="item.count > 0">
              ({{ item.count }})
            </template>
          </template>
        </v-select>
      </v-col>
      <v-icon color="rgba(79, 85, 167, 0.5)">mdi-menu-right</v-icon>
      <v-col cols="3">
        <v-select
          v-model="_projectId"
          :items="services"
          class="customer-box-form-input"
          hide-details
          placeholder="Related service type"
          outlined
          dense
          color="#4F55A7"
          item-color="#4F55A7"
          background-color="white"
          @change="$emit('change:service', $event)"
        >
          <template v-slot:item="{ item }">
            {{ item.text }}
            <template v-if="item.count > 0">
              ({{ item.count }})
            </template>
          </template>
          <template v-slot:selection="{ item }">
            {{ item.text }}
            <template v-if="item.count > 0">
              ({{ item.count }})
            </template>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="2" class="customer-box-detail-headline mb-3">物件名</v-col>
      <v-col cols="10" class="customer-box-detail-content font-weight-bold">
        {{ selectedSite?.name || '-' }}
      </v-col>
      <v-col cols="2" class="customer-box-detail-headline mb-3"
        >物件名（かな）</v-col
      >
      <v-col cols="10" class="customer-box-detail-content font-weight-bold">
        {{ selectedSite?.furigana_name || '-' }}
      </v-col>
      <v-col cols="2" class="customer-box-detail-headline mb-3"
        >サービス
      </v-col>
      <v-col cols="10" class="customer-box-detail-content">
        {{ selectedProject?.service_type.name || '-' }}
      </v-col>
      <v-col cols="2" class="customer-box-detail-headline mb-2">所在地</v-col>
      <v-col cols="10" class="customer-box-detail-content">
        <span class="customer-address" @click="goToAddress(address)">
          {{ address || '-' }}
          <v-icon v-if="address.trim().length" size="20">$newTab</v-icon>
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { omit } from 'lodash'
export default {
  name: 'SiteSelect',
  props: {
    customer: {
      type: Object,
      default: null,
      required: true
    },
    allServices: {
      type: Array,
      default: null,
      required: true
    },
    allPrefectures: {
      type: Array,
      default: null,
      required: true
    },
    projectId: {
      type: [Number, String],
      default: null,
      required: false
    }
  },
  computed: {
    _projectId: {
      get() {
        return this.projectId
      },
      set(newValue) {
        return this.$emit('update:project-id', newValue)
      }
    },
    siteId: {
      get() {
        return parseInt(this.$route.params?.site_id)
      },
      set(val) {
        this.$router.replace({
          name: this.$route.name,
          params: {
            customer_id: this.customer.id,
            site_id: val
          }
        })
      }
    },
    sites() {
      return this.customer.sites.map(site => {
        let count = 0
        if (this.isClaimOrAfterServicePage) {
          count = site.projects.reduce((sum, site) => {
            return sum + site?.claims_count + site?.coating_afters_count
          }, 0)
        } else if (this.$route.name === 'CustomerViewDetailTask') {
          count = site?.tasks_count
        } else if (this.$route.name === 'CustomerViewDetailPhotos') {
          count = site?.photos_count
        }
        return { value: site.id, text: site.name, count: count }
      })
    },
    selectedSite() {
      return this.customer?.sites?.find(site => site.id === this.siteId)
    },
    projects() {
      return this.selectedSite?.projects
    },
    selectedProject() {
      return this.projects?.find(project => project.id == this.projectId)
    },
    services() {
      return this.projects.map(project => {
        let count = 0
        if (this.isClaimOrAfterServicePage) {
          count =
            project.service_type_id === 0
              ? project?.coating_afters_count
              : project?.claims_count
        } else if (this.$route.name === 'CustomerViewDetailTask') {
          count = project?.tasks_count
        } else if (this.$route.name === 'CustomerViewDetailPhotos') {
          count = this.selectedSite?.photos_count
        }
        return {
          value: project.id,
          text: project.service_type.name,
          count: count
        }
      })
    },
    address() {
      let site = this.selectedSite
      let prefecture = this.allPrefectures.find(p => p.id == site.prefecture_id)
      return `${prefecture?.name || ''} ${site.city || ''} ${site.address ||
        ''}`
    },
    isClaimOrAfterServicePage() {
      return (
        this.$route.name === 'CustomerViewDetailClaim' ||
        this.$route.name === 'CustomerViewDetailAfterService'
      )
    }
  },

  methods: {
    goToAddress(address) {
      if (address != '-') {
        window.open(`https://www.google.com/maps?q=${address}`, '_blank')
      }
    }
  }
}
</script>

<style lang="scss" src="./SiteSelect.scss" scoped></style>
